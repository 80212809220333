import logo from './logotype.png';
import './App.css';
import barcode from './barcode.svg';
import arrowBack from './arrow-back.svg';
import creditCard from './credit-card.svg';
import {QrScanner} from '@yudiel/react-qr-scanner';
import {BarcodeFormat, DecodeHintType } from '@zxing/library';

import {NextUIProvider} from "@nextui-org/react";
import {Fragment, useState, useEffect} from "react";

import {
  Listbox,
  ListboxItem,
  Card,
  CardFooter,
  Image,
  Spacer,
} from "@nextui-org/react";

const primaryGreen = "#189C64";
const DeleteIcon = (props) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    height="1em"
    role="presentation"
    viewBox="0 0 20 20"
    width="1em"
    {...props}
  >
    <path
      d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M7.08331 4.14169L7.26665 3.05002C7.39998 2.25835 7.49998 1.66669 8.90831 1.66669H11.0916C12.5 1.66669 12.6083 2.29169 12.7333 3.05835L12.9166 4.14169"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M15.7084 7.61664L15.1667 16.0083C15.075 17.3166 15 18.3333 12.675 18.3333H7.32502C5.00002 18.3333 4.92502 17.3166 4.83335 16.0083L4.29169 7.61664"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M8.60834 13.75H11.3833"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      d="M7.91669 10.4167H12.0834"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);

const ScannerPage = ({setScanError, setScanned, products, scanError, removeScannedItem}) => {
  const scanDelayMs = 2000;
  const [isScanning, setScanning] = useState(true);
  const hints = new Map();
  const formats = [BarcodeFormat.EAN_13, BarcodeFormat.EAN_8];
  hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
  const stopDecoding = !isScanning;
  return (<div className="p-2">
      <div
        onClick={() => setScanning(current => !current)}
        className="rounded-medium overflow-hidden"
      >
        <QrScanner
          stopDecoding={stopDecoding}
          hints={hints}
          tracker={true}
          scanDelay={scanDelayMs}
          onDecode={(scanned) => setScanned(prev => [scanned, ...prev])}
          onError={setScanError}
          className="rounded-medium"
        />
      </div>
      <Spacer y={2} />
      <Listbox
        className="bg-primary text-white overflow-visible shadow-small rounded-medium"
        emptyContent="スキャンさらたバーコードがありません"
      >
        {products.map((item, index) => {
          if (typeof item === "object") {
            return <ListboxItem
              onPress={() => removeScannedItem(index)}
              startContent={<Image src={item.image_url} width={64} height={64} alt={item.name} />}
              endContent={<DeleteIcon/>}
            >
              {item.name}
            </ListboxItem>
          } else {
            return <ListboxItem>{item}</ListboxItem>
          }
        })}
      </Listbox>
      <span>{scanError !== null ? scanError?.message : ""}</span>
  </div>);
}

const HomePage = ({recommendations, purchases, products}) => {
  return (
    <>
      <div className="flex flex-row space-x-4 justify-center">
        {recommendations.map(recommendation =>
          <Card className="h-[300px]" isFooterBlurred key={recommendation.product_id}>
            <Image
              removeWrapper
              alt="Card background"
              className="z-0 w-full h-full object-cover"
              src={recommendation.image_url}
            />
            <CardFooter className="absolute flex flex-col bg-black/98 bottom-0 z-10 border-t-1 border-default-600" style={{backgroundColor: "rgba(0,0,0,0.4)"}}>
              <p className="text-tiny text-white/60 uppercase font-bold">おすすめ</p>
              <h4 className="text-white font-medium text-medium">{recommendation.name}</h4>
            </CardFooter>
          </Card>
        )}
      </div>
      <Spacer y={4} />
      <h2>食歴</h2>
      <div className="p-2">
        <Listbox
          className="bg-primary text-white overflow-visible shadow-small rounded-medium"
          emptyContent="食歴が見つかりませんでした"
        >
          {purchases.map((p, index) => {
            const item = p.item;
            const date = new Date();
            date.setTime(p.time);
            const timeText =   new Intl.DateTimeFormat('ja-JP', {
              dateStyle: 'long',
              timeStyle: 'medium',
            }).format(date);
            return <ListboxItem
              startContent={<Image src={item.image_url} width={64} height={64} alt={item.name} />}
              endContent={<span className="text-tiny p-4">{timeText}</span>}
              key={index}>
                {item.name}
              </ListboxItem>
          })}
        </Listbox>
      </div>
      <Spacer y={4} />
      <h2>料理一覧</h2>
      <div className="p-2">
        <Listbox
          className="bg-primary text-white overflow-visible shadow-small rounded-medium"
          emptyContent="料理情報読み込み中..."
        >
          {products.map((item, index) => {
            return <ListboxItem
              key={index}
              startContent={<Image src={item.image_url} width={64} height={64} alt={item.name} />}
              >
                {item.name}
              </ListboxItem>
          })}
        </Listbox>
      </div>
  </>);
}

const fetchScannedProducts = ({scanned, setProducts}) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const updates = scanned.map(item => {
    if (typeof item === "object") {
      return Promise.resolve(item);
    } else {
      return fetch("https://hal.cross-compass.com/api/items/"+item, {signal})
        .then(res => res.json())
        .then(body => ({type: "product", ...body}))
        .catch(err => {})
    }
  });
  Promise.all(updates).then(updated => {
    setProducts(updated)
  })
  return () => {
    controller.abort();
  }
};

const fetchRecommendations = ({userName, setRecommendations}) => {
  fetch("https://hal.cross-compass.com/api/popularity/"+userName)
    .then(res => res.json())
    .then(body => setRecommendations(body.recommendations))
  return () => {
  };
};


const fetchPurchases = ({userName, setPurchases}) => {
  fetch("https://hal.cross-compass.com/api/purchases/"+userName)
    .then(res => res.json())
    .then(setPurchases)
  return () => {
  };
};

const fetchMenu = ({setMenu}) => {
  fetch("https://hal.cross-compass.com/api/items")
    .then(res => res.json())
    .then(setMenu)
  return () => {
  };
};

const submitProducts = ({userName, products, onDone}) => {
  console.log({items: products});
  fetch("https://hal.cross-compass.com/api/checkout/"+userName, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({items: products}),
  }).then(onDone);
  return () => {
  };
};


function useConfirm(message, onConfirm, onAbort) {
  const confirm = () => {
    if(window.confirm(message))
      onConfirm();
    else
      onAbort();
  }
  return confirm
}

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const userParam = urlParams.get('user')
  if (userParam) {
    localStorage.setItem("user", userParam);
  }
  const userNameValue = localStorage.getItem("user");
  const userName = userNameValue !== null ? userNameValue : "グルメ太郎";
  const [stopDecoding, setStopDecoding] = useState(true);
  const [scanned, setScanned] = useState([]);
  const [products, setProducts] = useState([]);
  const [scanError, setScanError] = useState(null);
  const [position, setPosition] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [menu, setMenu] = useState([]);
  const [refreshToken, setRefreshToken] = useState(true);

  const removeScannedItem = (index) => {
    const nextScanned = [
      ...scanned.slice(0, index),
      ...scanned.slice(index+1),
    ];
    const nextPurchases = [
      ...purchases.slice(0, index),
      ...purchases.slice(index+1),
    ]
    setScanned(nextScanned);
    setPurchases(nextPurchases);
  };

  useEffect(() => {
    return fetchRecommendations({userName, setRecommendations});
  }, [userName, refreshToken]);

  useEffect(() => {
    return fetchPurchases({userName, setPurchases});
  }, [userName, refreshToken]);

  useEffect(() => {
    return fetchScannedProducts({scanned, setProducts});
  }, [scanned]);

  useEffect(() => {
    fetchMenu({setMenu});
  }, []);

  const clearScan = () => {
    setProducts([]);
    setScanned([]);
    setStopDecoding(true);
    setRefreshToken(t => !t);
  }

  const checkout = useConfirm(`${products.length}点を請求`, () => submitProducts({products, userName, onDone: () => {
    clearScan();
  }}), () => {})

  useEffect(() => {
    const hours = 60 * 60 * 1000;
    // using low accuracy position is faster
    const enableHighAccuracy = false;
    const positionOptions = {
      enableHighAccuracy: enableHighAccuracy,
      timeout: 4000,
      maximumAge: 1 * hours,
    };
    const positionError = (err) => {
      setPosition(null);
      console.warn(`Failed to get location E(${err.code}): ${err.message}`);
    }
    if (!stopDecoding) {
      navigator.geolocation.getCurrentPosition(
        (pos) => setPosition({
          lat: pos.coords.latitude,
          long: pos.coords.longitude,
          acc: pos.coords.accuracy,
        }),
        positionError,
        positionOptions,
      );
    }
  }, [stopDecoding]);

  // TODO: pass hints specifying the barcode formats
  const barcodeText = stopDecoding ? "バーコードスキャン開始" : "スキャン停止";
  const main = stopDecoding
    ? <HomePage recommendations={recommendations} purchases={purchases} products={menu}  />
    : <ScannerPage products={products} setScanned={setScanned} setScanError={setScanError} scanned={scanned} scanError={scanError} removeScannedItem={removeScannedItem} />;
  const footer = stopDecoding ?
          (<div className="flex flex-row justify-center h-24">
            <div className="flex flex-col" onClick={() => setStopDecoding(false)}>
              <img src={barcode} className="barcode" alt={barcodeText} />
              <span>{barcodeText}</span>
            </div>
          </div>)
    : (<div className="flex flex-row ml-8 items-center h-24" style={{justifyContent: "space-between"}}>
            <div className="flex flex-col" onClick={clearScan}>
              <Image src={arrowBack} alt="back" height={40} width={40} />
            </div>
            <div className="flex flex-col">
              <h2 className="text-2xl">{products.length ? `${products.length}点` : ""}</h2>
            </div>
            <div className="flex flex-col mr-8" onClick={checkout}>
              <img src={creditCard} alt="購入" />
              <span className="text-2xl">購入</span>
            </div>
          </div>);
  return (
    <NextUIProvider>
      <div className="App text-foreground bg-background">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p className="user">{userName}</p>
        </header>
        <main>
          {main}
        </main>
        <footer className="App-footer">
          {footer}
        </footer>
      </div>
    </NextUIProvider>
  );
}

export default App;
